import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/sections/layout"
import Seo from "../components/sections/seo"
import BlogTeaser from "../components/blog/blogTeaser"
import BlogPager from "../components/blog/blogPager"
import HeaderImage from "../components/headerImage"

import HomeImageSrc from "../media/images/blog_background.jpg"

// styled components
import styled from "styled-components"
import { fontSize } from "../css/mixins/mixins"

const BlogList = styled.ul`
  display: flex;
  flex-flow: wrap;
  flex-direction: column;

  margin-left: -15px;
  margin-right: -15px;

  margin-top: 80px;
  margin-bottom: 60px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`

const BlogItem = styled.li`
  flex-basis: 100%;
  margin: 10px;

  @media only screen and (min-width: 768px) {
    flex-basis: calc(50% - 20px);
  }
`

const H2Tag = styled.h2`
  text-align: center;
  ${fontSize(26)}
  margin-top: 20px;
  margin-bottom: 60px;
  position: relative;

  @media only screen and (min-width: 930px) {
    ${fontSize(30)}

    margin-top: 80px;
    margin-bottom: 80px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100px;
    height: 2px;
    bottom: -35px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #000;
  }
`

const BlogPage = ({ data }) => {
  const { edges, pageInfo } = data.allStrapiBlog
  const { pageCount, currentPage } = pageInfo

  return (
    <Layout>
      <Seo title="Fitness Journal" />
      <HeaderImage src={HomeImageSrc} title={"Fitness Journal"} />
      <div className="container">
        <H2Tag>
          Ty Paul Personal Trainer Hove & Brighton.
          <br />
          The Fitness Journal.
        </H2Tag>
        <BlogList>
          {edges !== null &&
            edges.map((edge, index) => {
              const { node } = edge
              return (
                <BlogItem key={index}>
                  <BlogTeaser data={node} slug={`/fitness-journal/${node.slug}`}/>
                </BlogItem>
              )
            })}
        </BlogList>
        <BlogPager currentPage={currentPage} pages={pageCount} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query myQuery($skip: Int!, $limit: Int!) {
    allStrapiBlog(
      sort: { order: DESC, fields: createdAt }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          main_image {
            url
          }
        }
      }
      pageInfo {
        pageCount
        perPage
        totalCount
        itemCount
        currentPage
      }
    }
  }
`

export default BlogPage
