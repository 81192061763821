import * as React from "react"
import { Link } from "gatsby"

import styled from "styled-components"

const BlogPagerList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 60px;
`
const BlogPagerItem = styled.li`
  color: #fff;
  background-color: #414141;
  padding: 5px 15px;
  border-bottom: 1px #8a8888 solid;
  transition: 0.3s background-color;

  border-radius: 5px;

  margin: 5px;

  &:hover,
  &.active {
    background-color: #917147;
  }
`

const BlogPager = ({ currentPage, pages }) => {
  const createPages = () => {
    const data = []

    let i = 1
    for (i; i <= pages; i++) {
      if (i === currentPage) {
        data.push(
          <BlogPagerItem key={i} className="active">
            {i}
          </BlogPagerItem>
        )
      } else {
        const link = i === 1 ? `/fitness-journal` : `/fitness-journal/${i}`
        data.push(
          <BlogPagerItem key={i}>
            <Link to={link}>{i}</Link>
          </BlogPagerItem>
        )
      }
    }

    return data
  }

  return <BlogPagerList className="blog-pager">{createPages()}</BlogPagerList>
}

export default BlogPager
